import { render, staticRenderFns } from "./InfoCard.html?vue&type=template&id=a38003a6&scoped=true&lang=html&"
import script from "./InfoCard.vue?vue&type=script&lang=js&"
export * from "./InfoCard.vue?vue&type=script&lang=js&"
import style0 from "./InfoCard.css?vue&type=style&index=0&id=a38003a6&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a38003a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VImg})
