

<script>
import { ref, watch } from 'vue-demi'
import BG_MAP from '@/assets/map.jpg'
import MARKER_ICON from '@/assets/icon.png'

export default {
  name: 'ElderlyMap',
  props:['markers','currentIndex'],
  components: {

  },
  setup (props, { emit }) {
    const map = ref(null)
    const originHeight = 984;
    const windowHeight = ref(window.innerHeight);
    const scaleRatio = ref(windowHeight.value / originHeight);

    const resizeObserver = new ResizeObserver(() => {
      windowHeight.value = window.innerHeight;
      scaleRatio.value = (windowHeight.value / originHeight);
    })
    resizeObserver.observe(document.body)

    const isActive = (marker) => {
      return marker.index === props.currentIndex
    }
    const showInfo = (data)=>{
      emit('showInfo', data)
    }

    const currentScrollLeft = ref(0)
    watch(()=>props.currentIndex,(index)=>{
      const currentData = props.markers.find((m) => m.index === index)
      showInfo(currentData)

      // scroll to marker position
      const nextScrollLeft = currentData.left - 400
      const gap = nextScrollLeft - currentScrollLeft.value
      if (gap > 0 && gap < 300 || gap < 0 && gap > -300) { return }
      currentScrollLeft.value = nextScrollLeft
      map.value.scrollTo({
        left: currentScrollLeft.value,
        behavior: 'smooth'
      });
    })

    const clickMap = ()=> {
      emit('clickMap')
    }

    return {
      BG_MAP,
      MARKER_ICON,
      scaleRatio,

      map,
      isActive,
      showInfo,

      clickMap,
    }
  }
}
</script>
<template lang="html" src="./ElderlyMap.html"></template>
<style lang="css" src="./ElderlyMap.css" scoped>
</style>