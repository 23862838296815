<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      width="450"
      class="elevation-10"
      app
    >
      <info-card
        :infoDataKey="infoDataKey"
        @close="drawer = false"
        @prev="prev"
        @next="next"
      />
    </v-navigation-drawer>
    <v-main>
      <elderly-map
        :markers="markerData"
        :currentIndex="currentIndex"
        @showInfo="showInfo"
        @clickMap="drawer = false"
      />
    </v-main>
  </v-app>
</template>

<script>
import { ref } from 'vue-demi'
import { markerData } from './data/marker'

import ElderlyMap from './components/ElderlyMap/ElderlyMap.vue'
import InfoCard from './components/InfoCard/InfoCard.vue'

export default {
  name: 'App',
  components: {
    ElderlyMap,
    InfoCard,
  },
  setup () {
    const drawer = ref(false)
    const infoDataKey = ref('fiveBigFamily')
    const currentIndex = ref(0)

    const showInfo = (data) => {
      infoDataKey.value = data.key
      drawer.value = true;
      currentIndex.value = data.index
    }

    const prev = () => {
      if (currentIndex.value === 0) {
        currentIndex.value = markerData.length-1
        return
      }

      currentIndex.value --
    }
    const next = () => {
      if (currentIndex.value === markerData.length-1) {
        currentIndex.value = 0
        return
      }

      currentIndex.value ++
    }

    return {
      drawer,
      infoDataKey,
      currentIndex,
      markerData,

      showInfo,
      prev,
      next,
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;500;600&display=swap');
.v-application {
  font-family: 'Noto Serif TC', serif;
  font-weight: 500;
}
 /* hide scrollbar */
body, .v-application div {
  scrollbar-width: none; /* for Firefox */
}
body::-webkit-scrollbar, .v-application div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

h3,h4 {
  font-weight: 600;
}
</style>