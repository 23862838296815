

<script>
import { ref, computed, watch } from 'vue-demi'
import { originData } from '../../data/originData'

export default {
  name: 'InfoCard',
  props: ['infoDataKey'],
  setup (props, { emit }) {
    const info = computed(()=>{
      return originData.find(d=>{
        return d.key === props.infoDataKey
      })
    })

    watch(info, () => { tab.value = 'story' })

    const tab = ref('story')

    const viewStory = () => {
      tab.value = 'story'
    }
    const viewOthers = () => {
      tab.value = 'others'
    }

    const close = () => {
      emit('close')
    }

    const prev = () => {
      emit('prev')
    }
    const next = () => {
      emit('next')
    }

    return {
      info,
      tab,
      viewStory,
      viewOthers,
      close,
      prev,
      next,
    }
  }
}
</script>
<template lang="html" src="./InfoCard.html"></template>
<style lang="css" src="./InfoCard.css" scoped></style>