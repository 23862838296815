import IMG_tjaruigi from '@/assets/tjaruigi.png'
import IMG_p_tjaruigi from '@/assets/tjaruigi-p.png'
import IMG_tapuljaljuy from '@/assets/tapuljaljuy.png'
import IMG_maligiligilj from '@/assets/maligiligilj.png'
import IMG_tjamatja_an from '@/assets/tjamatja-an.png'
import IMG_lupiliyan from '@/assets/lupiliyan.png'
import IMG_aulivan_2 from '@/assets/aulivan-2.png'
import IMG_tjaragidis from '@/assets/tjaragidis.png'
import IMG_p_tjaragidis from '@/assets/tjaragidis-p.png'
import IMG_rupeljengan from '@/assets/rupeljengan.png'
import IMG_p_rupeljengan from '@/assets/rupeljengan-p.png'
import IMG_tjaruigi_2 from '@/assets/tjaruigi-2.png'
import IMG_rudrapidrap from '@/assets/rudrapidrap.png'
import IMG_rudrapidrap_2 from '@/assets/rudrapidrap-2.png'
import IMG_aulivan from '@/assets/aulivan.png'
import IMG_p_aulivan from '@/assets/aulivan-p.png'

export const originData = [
  {
    key: 'tapuljaljuy',
    name: 'tapuljaljuy(達普拉瑞)家族家屋遺址',
    story: {
      title: '白鷺部落五大家族',
      img: IMG_tapuljaljuy,
      descriptions:'組成白鷺部落的族人可區分為五大主要家族。其中四個家族依照其對應於部落的核心程度及納入的先後順序，分別是tjamatjaqan（佳馬嘉岸）、rupiliyan（盧比黎亞）、aulivan（奧利萬）、以及maligiligilj（馬力給利給了）。<br/>族人定居在老部落許久後，再從tjamatjaqan（佳馬嘉岸）家族分設出tapuljaljuy（達普拉瑞）家族。資料來源：炭火相傳payljus白鷺部落文史紀錄'
    },
  },
  {
    key: 'maligiligilj',
    name: 'maligiligilj(馬力給利給了)家族',
    story: {
      title: '白鷺部落五大家族',
      img: IMG_maligiligilj,
      descriptions:'組成白鷺部落的族人可區分為五大主要家族。其中四個家族依照其對應於部落的核心程度及納入的先後順序，分別是tjamatjaqan（佳馬嘉岸）、rupiliyan（盧比黎亞）、aulivan（奧利萬）、以及maligiligilj（馬力給利給了）。<br/>族人定居在老部落許久後，再從tjamatjaqan（佳馬嘉岸）家族分設出tapuljaljuy（達普拉瑞）家族。<br/><br/>資料來源：炭火相傳payljus白鷺部落文史紀錄'
    },
  },
  {
    key: 'tjamatja-an',
    name: 'tjamatja-an(佳馬嘉岸)家族家屋遺址',
    story: {
      title: '白鷺部落五大家族',
      img: IMG_tjamatja_an,
      descriptions:'組成白鷺部落的族人可區分為五大主要家族。其中四個家族依照其對應於部落的核心程度及納入的先後順序，分別是tjamatjaqan（佳馬嘉岸）、rupiliyan（盧比黎亞）、aulivan（奧利萬）、以及maligiligilj（馬力給利給了）。<br/>族人定居在老部落許久後，再從tjamatjaqan（佳馬嘉岸）家族分設出tapuljaljuy（達普拉瑞）家族。資料來源：炭火相傳payljus白鷺部落文史紀錄'
    },
  },
  {
    key: 'lupiliyan',
    name: 'lupiliyan(盧比黎亞)家族',
    story: {
      title: '白鷺部落五大家族',
      img: IMG_lupiliyan,
      descriptions:'組成白鷺部落的族人可區分為五大主要家族。其中四個家族依照其對應於部落的核心程度及納入的先後順序，分別是tjamatjaqan（佳馬嘉岸）、rupiliyan（盧比黎亞）、aulivan（奧利萬）、以及maligiligilj（馬力給利給了）。<br/>族人定居在老部落許久後，再從tjamatjaqan（佳馬嘉岸）家族分設出tapuljaljuy（達普拉瑞）家族。資料來源：炭火相傳payljus白鷺部落文史紀錄'
    },
  },
  {
    key: 'aulivan-2',
    name: 'aulivan(奧利萬)家族',
    story: {
      title: '白鷺部落五大家族',
      img: IMG_aulivan_2,
      descriptions:'組成白鷺部落的族人可區分為五大主要家族。其中四個家族依照其對應於部落的核心程度及納入的先後順序，分別是tjamatjaqan（佳馬嘉岸）、rupiliyan（盧比黎亞）、aulivan（奧利萬）、以及maligiligilj（馬力給利給了）。<br/>族人定居在老部落許久後，再從tjamatjaqan（佳馬嘉岸）家族分設出tapuljaljuy（達普拉瑞）家族。資料來源：炭火相傳payljus白鷺部落文史紀錄'
    },
  },
  {
    key: 'tjaruigi',
    name:'部落耆老 李百花',
    story: {
      title:'二戰期間舊白鷺部落遭到美軍轟炸',
      img: IMG_tjaruigi,
      descriptions:'根據李百花耆老口述，在舊部落時期，因為第二次世界大戰的關係，當時日本與美國的衝突日益擴散，波及到當時日本所統治的臺灣，甚至到舊白鷺部落。<br/>李百花耆老闡述：她10歲時，居民都沒人待在部落裡，因為二戰關係，舊白鷺部落遭到美軍轟炸，導致部落族人到處在山林裡頭尋找安全處以躲避美軍的攻擊。'
    },
    others:{
      title:'家傳物品',
      img: IMG_p_tjaruigi,
      descriptions:'這三支鉤針為李百花耆老的家傳鉤針，以年代依序排列，上面鉤針纏繞黑線與黃線為李百花耆老的曾祖母所使用的鉤針，未纏繞線的鉤針為曾祖母的母親所使用的鉤針。'
    }
  },
  {
    key: 'tjaragidis',
    name:'部落耆老 何月美',
    story: {
      title:'部落首位女獵人',
      img: IMG_tjaragidis,
      descriptions:'在過去排灣族部落中，狩獵通常是由部落男性族人扛起以延續山林狩獵文化。但在這次訪問何月美耆老時，她卻透露出另外一則關於狩獵的小故事。<br/>她提到在舊部落時期，她的祖母在山上耕田種地瓜時，身旁忽然出現一隻小山豬，祖母便趕快用手上的農耕器具（vuka）往小山豬身上戳，小山豬受傷後，逃了將近200公尺，但牠因為受了傷，故體力不支倒在田邊，最後就被她的祖母捕獲，也另類的成為部落中首位捕獲山豬的女性。'
    },
    others:{
      title:'家傳物品',
      img: IMG_p_tjaragidis,
      descriptions:'此苧麻線為何月美耆老保存的材料之一，並說到在過去舊部落會用來織布或是鉤織成sikau。隨著國民政府山地平地化政策，何月美耆老將她收藏的材料也一併帶至新址（南和部落）。'
    }
  },
  {
    key: 'rupeljengan',
    name:'部落耆老 林時吉',
    story: {
      title:'部落野外放牛時被虎頭蜂叮的故事',
      img: IMG_rupeljengan,
      descriptions:'林時吉耆老跟我們分享一則令他難忘的故事，童年孝順的他，每到假日都會與部落同年齡的朋友相約去部落外的野地上放牛。<br/>他提到：有次放牛時，不小心驚擾野外的虎頭蜂，在沒有任何遮蔽物之下，為了顧到牛群而被虎頭蜂叮得滿身是包。因為當時部落裡沒有醫生，所以回到家後，他祖母為了醫治他，便會煮一些藥草讓他喝，而當時林時吉耆老的朋友來探病，看到他滿身是包的樣子，因此被嚇到，誤以為他是怪物，差點拿刀砍傷他，所幸被祖母阻止。'
    },
    others:{
      title:'家傳物品',
      img: IMG_p_rupeljengan,
      descriptions:'此照片為林時吉耆老所收藏的捕獸夾，此捕獸夾承載著在尚未遷移至南和現址前，在舊白鷺部落獵場與父親的美好回憶。。'
    }
  },
  {
    key: 'tjaruigi-2',
    name:'部落耆老 李英珠',
    story: {
      title:'吃樹蟲治鼻涕',
      img: IMG_tjaruigi_2,
      descriptions:'李英珠耆老跟我們提到一則童年故事，她提到小時候不知道為什麼時常流鼻涕，而她的舅舅為了治好她的這個症狀，便會去山林中採集樹蟲，並帶回家以烘烤方式給她食用。雖然樹蟲吃起來甜甜的，不過她真的不敢吃，現在看到樹蟲也還是會怕。'
    }
  },
  {
    key: 'rudrapidrap',
    name:'部落耆老 溫水梳',
    story: {
      title:'男的接生婆',
      img: IMG_rudrapidrap,
      descriptions:'溫水梳耆老向我們說了一則關於部落男生─接生婆的故事，她提到在舊白鷺時期有兩位接生婆，一位女性、一位男性。原則上女性接生婆常駐於舊白鷺部落，而且也是舊白鷺族人，另一位男性接生婆，他則是來自舊古樓部落的醫生。<br/>若舊白鷺部落有需要接生，恰巧女性接生婆又剛好不在部落時，我們就必須以徒步方式，去海拔高的舊古樓部落請那位男醫生來舊白鷺部落幫忙接生。'
    }
  },
  {
    key: 'rudrapidrap-2',
    name:'部落獵人 王長治',
    story: {
      title:'獵場的故事—迷航之地',
      img: IMG_rudrapidrap_2,
      descriptions:'王長治是一位部落的獵人(cinunan)，他常常獨自上山打獵並熟知舊白鷺部落的獵場，擁有豐富的狩獵知識。<br/>他分享了一則關於獵場的故事：在九保山，也就是舊白鷺部落獵場有個地點稱為akulavan（阿古拉凡）。為何稱為akulavan 呢？因為此地點的林相較平穩，進入此森林中容易迷路分不出方向，因此老人家就把那地點稱為akulavan意思為迷航之地。'
    }
  },
  {
    key: 'aulivan',
    name:'南和部落村長 陳文山',
    story: {
      title:'男女有別！',
      img: IMG_aulivan,
      descriptions:'陳文山目前為南和部落現任村長，同時也是來義鄉獵人協會總幹事。田調訪談時，村長跟我們說了一則有趣的故事，同時也呼應到過去嚴謹的傳統制度。<br/>在aulivan（奧力萬）家族故事提到，從前有一個望嘉部落的男丁看到aulivan的女孩子在河的對岸，於是這位女孩子挑釁，故意掀開裙子對這位望嘉部落的男丁說，如果你可以從對岸游過來我就隨你。於是這位望嘉部落男丁便想盡辦法游過來，想要得到這位aulivan家族的女子，最後這位望嘉部落的男丁順著橫躺在河道中的枯木順利游到對岸，而對岸女子看到這舉動後便嚇到倉皇逃回白鷺部落，留下她挑釁的那位男子。<br/>也因為這件事造成部落間的談判，並導致aulivan家族將一塊土地賠償給那位成功游到對岸，來自望嘉部落的男丁。村長說家族中的這個故事告訴我們過去男女之間不可以隨便亂來，否則會損失更大。'
    },
    others:{
      title:'家傳物品',
      img: IMG_p_aulivan,
      descriptions:'此陶壺為村長收藏之一，因為年代較久遠，村長怕陶壺破損，故沒有拿起給我們拍攝。'
    }
  },
]

export default {
  originData
}
