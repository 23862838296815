export const markerData = [
  // 五大家族
  {
    index: 0,
    key: "tapuljaljuy",
    top: 546,
    left: 599,
    width: 68,
    height: 28,
  },
  {
    index: 1,
    key: "maligiligilj",
    top: 546,
    left: 803,
    width: 68,
    height: 28,
  },
  {
    index: 2,
    key: "tjamatja-an",
    top: 471,
    left: 1328,
    width: 83,
    height: 30,
    rotate: -5
  },
  {
    index: 3,
    key: "lupiliyan",
    top: 441,
    left: 1543,
    width: 75,
    height: 30,
    rotate: -5
  },
  {
    index: 4,
    key: "aulivan-2",
    top: 352,
    left: 1474,
    width: 83,
    height: 30,
    rotate: -6
  },
  // 李百花
  {
    index: 5,
    key: "tjaruigi",
    top: 448,
    left: 1817,
    width: 61,
    height: 27,
    rotate: -6
  },
  // 何月美
  {
    index: 6,
    key: "tjaragidis",
    top: 537,
    left: 906,
    width: 57,
    height: 27,
  },
  // 林時吉
  {
    index: 7,
    key: "rupeljengan",
    top: 431,
    left: 1263,
    width: 67,
    height: 27,
    rotate: -5
  },
  // 李英珠
  {
    index: 8,
    key: "tjaruigi-2",
    top: 525,
    left: 1111,
    width: 55,
    height: 27,
    rotate: -5
  },
  // 溫水梳
  {
    index: 9,
    key: "rudrapidrap",
    top: 449,
    left: 951,
    width: 68,
    height: 27,
  },
  // 溫水梳
  {
    index: 10,
    key: "rudrapidrap-2",
    top: 480,
    left: 984,
    width: 68,
    height: 27,
    rotate: -6
  },
  // 陳文山
  {
    index: 11,
    key: "aulivan",
    top: 371,
    left: 1404,
    width: 68,
    height: 27,
    rotate: -6
  }
]

export default {
  markerData
}
